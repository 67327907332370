import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import Img, { FluidObject } from "gatsby-image"
import { loadStripe } from "@stripe/stripe-js"

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      "pk_live_51JYY4cGlZNC0CUqJD1FCCX91Qp0Yh2rsTYxr3ID7aDJZq7ZMY07bgDIbnw4oHY5A7oUHhFNPhIzzZH6gBZvI13BB004Ysj2Jrz"
    )
  }
  return stripePromise
}

const ListItem = ({ logo, image, header, description, enabled, platform }) => {
  const stripeCheckout = async (
    event,
    code = "price_1JhHo7GlZNC0CUqJvzZjzhtU"
  ) => {
    event.preventDefault()

    const stripe = await getStripe()

    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [{ price: code, quantity: 1 }],
      successUrl: `http://www.theinfluencerlist.co/success`,
      cancelUrl: `http://www.theinfluencerlist.co/cancel`,
    })

    if (error) {
      alert(`An error occured ${error.message}`)
    }
  }

  const data = useStaticQuery(graphql`
    query IGListItemQuery {
      list: file(relativePath: { eq: "showcase-list2.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      profile: file(relativePath: { eq: "showcase-profile.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const [expanded, toggleExpanded] = useState(false)
  if (platform !== "instagram") {
    return (
      <div
        className="w-full relative flex items-center justify-center"
        style={{ color: "transparent", textShadow: "0 0 5px rgba(0,0,0,0.2)" }}
      >
        <img
          className="absolute w-48 h-48 top-0 left-0 opacity-50"
          src={logo}
        />
        <div
          className={`my-24 border-${platform} mx-16 flex flex-col justify-center relative bg-white shadow-custom w-full z-50`}
        >
          <div className="flex justify-center ">
            <div className="w-1/5 flex justify-center my-4 items-center">
              <Img
                fluid={data.list.childImageSharp.fluid}
                alt={`list example`}
                className="rounded-md h-32 opacity-25 w-32"
              />
            </div>
            <div className="w-3/5 my-4">
              <div>
                <h2 className="text-center underline text-black">{header}</h2>
                <span className="font-sans">Data included in list:</span>
              </div>
              <div className="grid grid-cols-5 grid-rows-3 gap-1 mb-4 w-full text-transparent text-xs font-sans">
                <div
                  title="The influencers id/handle on that platform"
                  className="bg-gray-200 font-mono  flex items-center justify-between border border-gray-200 px-1 pr-2 rounded leading-tight"
                >
                  Username{" "}
                  <span>
                    <svg
                      className="fill-current h-3 w-3 text-gray-700  ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </span>
                </div>
                <div
                  title="Clickable link to their profile"
                  className="bg-gray-200 font-mono  flex items-center justify-between border border-gray-200 px-1 pr-2 rounded leading-tight"
                >
                  Link to profile
                  <span>
                    <svg
                      className="fill-current h-3 w-3 text-gray-700  ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </span>
                </div>
                <div
                  title="How many users are following the influencer"
                  className="bg-gray-200 font-mono  flex items-center justify-between border border-gray-200 px-1 pr-2 rounded leading-tight"
                >
                  Follower count
                  <span>
                    <svg
                      className="fill-current h-3 w-3 text-gray-700  ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </span>
                </div>
                <div
                  title="How many users the influencer is following"
                  className="bg-gray-200 font-mono  flex items-center justify-between border border-gray-200 px-1 pr-2 rounded leading-tight"
                >
                  Following count{" "}
                  <span>
                    <svg
                      className="fill-current h-3 w-3 text-gray-700  ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </span>
                </div>
                <div
                  title="How many posts they have uploaded to the platform"
                  className="bg-gray-200 font-mono  flex items-center justify-between border border-gray-200 px-1 pr-2 rounded leading-tight"
                >
                  Total posts
                  <span>
                    <svg
                      className="fill-current h-3 w-3 text-gray-700  ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </span>
                </div>
                <div
                  title="How many followers they have compared to following"
                  className="bg-gray-200 font-mono  flex items-center justify-between border border-gray-200 px-1 pr-2 rounded leading-tight"
                >
                  Follower ratio{" "}
                  <span>
                    <svg
                      className="fill-current h-3 w-3 text-gray-700  ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </span>
                </div>
                <div
                  title="In what category they are actively posting"
                  className="bg-gray-200 font-mono  flex items-center justify-between border border-gray-200 px-1 pr-2 rounded leading-tight"
                >
                  Category{" "}
                  <span>
                    <svg
                      className="fill-current h-3 w-3 text-gray-700  ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </span>
                </div>
                <div
                  title="Example stories that the influncer has posted"
                  className="bg-gray-200 font-mono  flex items-center justify-between border border-gray-200 px-1 pr-2 rounded leading-tight"
                >
                  Sample Posts{" "}
                  <span>
                    <svg
                      className="fill-current h-3 w-3 text-gray-700  ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </span>
                </div>
                {/*            <div
                title="The language they use in their posts"
                className="bg-gray-200 flex items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
              >
                Language{" "}
                <span>
                  <svg
                    className="fill-current h-3 w-3 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
  </div>*/}
                {/*         <div
                title="The country they are living in"
                className="bg-gray-200 flex items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
              >
                Country{" "}
                <span>
                  <svg
                    className="fill-current h-3 w-3 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div> */}
                <div
                  title="Percentage of engagement on each post"
                  className="bg-gray-200 font-mono  flex items-center justify-between border border-gray-200 px-1 pr-2 rounded leading-tight"
                >
                  Engagement rate{" "}
                  <span>
                    <svg
                      className="fill-current h-3 w-3 text-gray-700  ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </span>
                </div>
                <div
                  title="How many likes they usually recieve on their posts"
                  className="bg-gray-200 font-mono  flex items-center justify-between border border-gray-200 px-1 pr-2 rounded leading-tight"
                >
                  Average likes{" "}
                  <span>
                    <svg
                      className="fill-current h-3 w-3 text-gray-700  ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </span>
                </div>
                <div
                  title="How many comments they usually recieve on their posts"
                  className="bg-gray-200 font-mono  flex items-center justify-between border border-gray-200 px-1 pr-2 rounded leading-tight"
                >
                  Average comments{" "}
                  <span>
                    <svg
                      className="fill-current h-3 w-3 text-gray-700  ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </span>
                </div>
                <div
                  title="Collection of hashtags that they usually use on their posts"
                  className="bg-gray-200 font-mono  flex items-center justify-between border border-gray-200 px-1 pr-2 rounded leading-tight"
                >
                  Hashtags{" "}
                  <span>
                    <svg
                      className="fill-current h-3 w-3 text-gray-700  ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </span>
                </div>
                <div
                  title="How many comments they recieve for each like"
                  className="bg-gray-200 font-mono  flex items-center justify-between border border-gray-200 px-1 pr-2 rounded leading-tight"
                >
                  Like/Comment ratio{" "}
                  <span>
                    <svg
                      className="fill-current h-3 w-3 text-gray-700  ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </span>
                </div>
                {/*            <div
                title="How recent our data of the influencer is"
                className="bg-gray-200 flex items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
              >
                Data age{" "}
                <span>
                  <svg
                    className="fill-current h-3 w-3 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
  </div>*/}
              </div>
            </div>
            <div className="w-2/5 font-sans flex flex-col justify-around my-4">
              <p className="text-gray-700 text-sm pt-1 px-2">
                This list has no release for 2022 yet. You can join the waitlist
                and get notified when we release the first version by clicking
                the waitlist button and adding your e-mail.
              </p>
              <p className="text-gray-700 text-center">Starting from $39</p>
              {expanded ? (
                <form
                  className=" max-w-sm py-2 mx-auto"
                  name="list-interest"
                  method="POST"
                  //@ts-ignore
                  data-netlify="true"
                >
                  <div className="md:flex items-center">
                    <input
                      type="hidden"
                      className="hidden"
                      name="form-name"
                      value={`${platform}`}
                    />
                    <input
                      className="py-4 md:py-2 appearance-none bg-transparent border-b-2 w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      type="text"
                      name="email"
                      placeholder="Your email"
                      aria-label="Email Adress"
                    />
                    <input
                      className="flex-shrink-0 bg-light-blue hover:shadow-xl border-light-blue hover:text-black text-sm border-4 text-white py-1 px-2 rounded-sm"
                      type="submit"
                      value="Notify Me"
                    />
                    <input type="hidden" name="platform" value={platform} />
                  </div>
                </form>
              ) : (
                <div className="flex">
                  <Link
                    to="/custom-list/"
                    className="bg-dark-green w-32 mx-auto flex items-center justify-center px-3 py-2 border border-transparent text-base leading-6 font-bold rounded-sm text-white hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out"
                  >
                    Create List
                  </Link>
                  <div
                    onClick={() => toggleExpanded(!expanded)}
                    className="bg-light-blue cursor-pointer w-32 mx-auto flex items-center justify-around px-3 py-2 border border-transparent text-sm leading-6 font-bold rounded-sm text-white hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out"
                  >
                    Waitlist
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="w-full relative flex items-center justify-center">
      <img className="absolute w-48 h-48 top-0 left-0 opacity-50" src={logo} />
      <div
        className={`my-24 border-${platform} mx-16 flex flex-col justify-center relative bg-white shadow-custom w-full z-50`}
      >
        <div className="flex justify-center ">
          <div className="w-1/5 flex justify-center my-4 items-center">
            <Img
              fluid={data.list.childImageSharp.fluid}
              alt={`list example`}
              className="rounded-md h-32  w-32"
            />
          </div>
          <div className="w-3/5 my-4">
            <div>
              <h2 className="text-center underline">{header}</h2>
              <span className="font-sans">Data included in list:</span>
            </div>
            <div className="grid grid-cols-5 grid-rows-3 gap-1 mb-4 w-full text-xs font-sans">
              <div
                title="The influencers id/handle on that platform"
                className="bg-gray-200 font-mono flex items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
              >
                Username{" "}
                <span>
                  <svg
                    className="fill-current h-3 w-3 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="Clickable link to their profile"
                className="bg-gray-200 font-mono flex items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
              >
                Link to profile
                <span>
                  <svg
                    className="fill-current h-3 w-3 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="How many users are following the influencer"
                className="bg-gray-200 font-mono flex items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
              >
                Follower count
                <span>
                  <svg
                    className="fill-current h-3 w-3 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="How many users the influencer is following"
                className="bg-gray-200 font-mono flex items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
              >
                Following count{" "}
                <span>
                  <svg
                    className="fill-current h-3 w-3 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="How many posts they have uploaded to the platform"
                className="bg-gray-200 font-mono flex items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
              >
                Total posts
                <span>
                  <svg
                    className="fill-current h-3 w-3 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="How many followers they have compared to following"
                className="bg-gray-200 font-mono flex items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
              >
                Follower ratio{" "}
                <span>
                  <svg
                    className="fill-current h-3 w-3 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="In what category they are actively posting"
                className="bg-gray-200 font-mono flex items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
              >
                Category{" "}
                <span>
                  <svg
                    className="fill-current h-3 w-3 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="Example stories that the influncer has posted"
                className="bg-gray-200 font-mono flex items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
              >
                Sample Posts{" "}
                <span>
                  <svg
                    className="fill-current h-3 w-3 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              {/*            <div
              title="The language they use in their posts"
              className="bg-gray-200 flex items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
            >
              Language{" "}
              <span>
                <svg
                  className="fill-current h-3 w-3 text-gray-700  ml-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                </svg>
              </span>
</div>*/}
              {/*         <div
              title="The country they are living in"
              className="bg-gray-200 flex items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
            >
              Country{" "}
              <span>
                <svg
                  className="fill-current h-3 w-3 text-gray-700  ml-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                </svg>
              </span>
            </div> */}
              <div
                title="Percentage of engagement on each post"
                className="bg-gray-200 font-mono flex items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
              >
                Engagement rate{" "}
                <span>
                  <svg
                    className="fill-current h-3 w-3 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="How many likes they usually recieve on their posts"
                className="bg-gray-200 font-mono flex items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
              >
                Average likes{" "}
                <span>
                  <svg
                    className="fill-current h-3 w-3 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="How many comments they usually recieve on their posts"
                className="bg-gray-200 font-mono flex items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
              >
                Average comments{" "}
                <span>
                  <svg
                    className="fill-current h-3 w-3 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="Collection of hashtags that they usually use on their posts"
                className="bg-gray-200 font-mono flex items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
              >
                Hashtags{" "}
                <span>
                  <svg
                    className="fill-current h-3 w-3 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="How many comments they recieve for each like"
                className="bg-gray-200 font-mono flex items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
              >
                Like/Comment ratio{" "}
                <span>
                  <svg
                    className="fill-current h-3 w-3 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              {/*            <div
              title="How recent our data of the influencer is"
              className="bg-gray-200 flex items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
            >
              Data age{" "}
              <span>
                <svg
                  className="fill-current h-3 w-3 text-gray-700  ml-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                </svg>
              </span>
</div>*/}
            </div>
          </div>
          <div className="w-2/5 font-sans flex flex-col justify-around my-4">
            <p className="text-gray-700 text-sm pt-1 px-2">
              A complete list of micro influencers available through the{" "}
              <Link
                to="https://app.omnifluence.io"
                className="underline"
                target="_blank"
              >
                Omnifluence
              </Link>{" "}
              platform.
              <br />
              {platform === "instagram" && (
                <>
                  Free sample (without the in depth metrics) is available on
                  signup{" "}
                  <Link
                    to="https://app.omnifluence.io/sign-up/sample"
                    className="underline"
                  >
                    here
                  </Link>
                </>
              )}
            </p>
            <p className="text-gray-700 text-center">Starting from $39</p>
            <div className="flex">
              <Link
                to="/custom-list/"
                className="bg-dark-green w-32 mx-auto flex items-center justify-center px-3 py-2 border border-transparent text-base leading-6 font-bold rounded-sm text-white hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out"
              >
                Create List
              </Link>
              <div
                onClick={() => toggleExpanded(!expanded)}
                className="bg-light-blue cursor-pointer w-32 mx-auto flex items-center justify-around px-3 py-2 border border-transparent text-sm leading-6 font-bold rounded-sm text-white hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out"
              >
                Choose List
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-4"
                  viewBox="0 0 32 32"
                  fill="white"
                >
                  <path d="M 4.21875 10.78125 L 2.78125 12.21875 L 15.28125 24.71875 L 16 25.40625 L 16.71875 24.71875 L 29.21875 12.21875 L 27.78125 10.78125 L 16 22.5625 Z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        {expanded && (
          <div className="font-sans w-5/6 mx-auto border-t-2 border-gray-600 mt-8 pt-4">
            <h3 className="text-center ">Select a list</h3>
            <ul className="divide-y divide-gray-400">
              <li className="flex justify-between items-center my-4 py-4">
                <div>
                  <Img
                    fluid={data.list.childImageSharp.fluid}
                    alt={`list example`}
                    className="rounded-md h-24  w-24"
                  />
                </div>
                <div>
                  <h3 className="text-center">
                    UK | Sports & Fitness | 600 Influencers
                  </h3>
                  <div className="flex">
                    <div
                      title="Category"
                      className="bg-gray-300 mx-1 flex font-mono text-sm items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
                    >
                      Sports & Fitness{" "}
                    </div>
                    <div
                      title="Country"
                      className="bg-gray-300 mx-2 flex font-mono text-sm items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
                    >
                      United Kingdom
                    </div>
                    <div
                      title="Size"
                      className="bg-gray-300 mx-2 flex font-mono text-sm items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
                    >
                      600 Influencers{" "}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="text-center">$39</div>
                  <div
                    onClick={e => {
                      stripeCheckout(e, "price_1KD8ZLGlZNC0CUqJZ5ea5srS")
                    }}
                    className="bg-light-blue cursor-pointer w-32 mx-auto flex items-center justify-around px-3 py-2 border border-transparent text-sm leading-6 font-bold rounded-md text-white hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out"
                  >
                    Order now
                  </div>
                </div>
              </li>
              <li className="flex justify-between items-center my-4 py-4">
                <div>
                  <Img
                    fluid={data.list.childImageSharp.fluid}
                    alt={`list example`}
                    className="rounded-md h-24  w-24"
                  />
                </div>
                <div>
                  <h3 className="text-center">
                    NA & Europe | 2000 Influencers
                  </h3>
                  <div className="flex">
                    <div
                      title="Category"
                      className="bg-gray-300 mx-1 flex font-mono text-sm items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
                    >
                      All Niches{" "}
                    </div>
                    <div
                      title="Country"
                      className="bg-gray-300 mx-2 flex font-mono text-sm items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
                    >
                      North America & Europe
                    </div>
                    <div
                      title="Size"
                      className="bg-gray-300 mx-2 flex font-mono text-sm items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
                    >
                      2000 Influencers{" "}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="text-center">$89</div>
                  <div
                    onClick={e => {
                      stripeCheckout(e, "price_1JhHo7GlZNC0CUqJvzZjzhtU")
                    }}
                    className="bg-light-blue cursor-pointer w-32 mx-auto flex items-center justify-around px-3 py-2 border border-transparent text-sm leading-6 font-bold rounded-md text-white hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out"
                  >
                    Order now
                  </div>
                </div>
              </li>
              <li className="flex justify-between items-center my-4 py-4">
                <div>
                  <h3 className="text-center">Full Database Access</h3>
                </div>
                <div>
                  <div className="flex">
                    <div
                      title="Category"
                      className="bg-gray-300 mx-1 flex font-mono text-sm items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
                    >
                      Any Niche
                    </div>
                    <div
                      title="Country"
                      className="bg-gray-300 mx-2 flex font-mono text-sm items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
                    >
                      All Countries
                    </div>
                    <div
                      title="Size"
                      className="bg-gray-300 mx-2 flex font-mono text-sm items-center justify-between border border-gray-200 text-gray-800 px-1 pr-2 rounded leading-tight"
                    >
                      2000 Influencers <br /> + 500 Monthly
                    </div>
                  </div>
                </div>
                <div>
                  <div className="w-full text-center mx-auto">$28/Month</div>
                  <Link
                    to="https://app.omnifluence.io/sign-up/sample"
                    className="underline cursor-pointer w-32 mx-auto flex items-center justify-around px-3 py-2 border border-transparent text-sm leading-6 font-bold rounded-md text-black focus:outline-none border-light-blue focus:shadow-outline-indigo transition duration-150 ease-in-out"
                  >
                    Try for free
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default ListItem
