import React, { useState } from "react"
import SEO from "../components/seo"
import CTA from "../components/cta"
import Layout from "../components/layout"
import { loadStripe } from "@stripe/stripe-js"
import GuaranteeBadge from "../components/guarantee-badge"
import { Link } from "gatsby"
import ListItem from "../components/lists/list-item"
import instagram from "../logos/instagram.svg"
import reddit from "../logos/reddit.svg"
import tiktok from "../logos/tiktok.svg"
import twitch from "../logos/twitch.svg"
import twitter from "../logos/twitter.svg"
import youtube from "../logos/youtube.svg"

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      "pk_live_51JYY4cGlZNC0CUqJD1FCCX91Qp0Yh2rsTYxr3ID7aDJZq7ZMY07bgDIbnw4oHY5A7oUHhFNPhIzzZH6gBZvI13BB004Ysj2Jrz"
    )
  }
  return stripePromise
}

const Lists = () => {
  const faq = [
    {
      q: "How does it work?",
      a:
        "Once you've purchased a list you will get a link to a google data sheet as well as a download link in your e-mail that you can use to download the file. Note that it can take up to 24hours for the link to generate.",
    },
    {
      q: "Do you work with the influencers on the list?",
      a:
        "We don't have any relationship with the influencers on theinfluencerlist. They are chosen based on their content and metrics alone.",
    },
    {
      q: "Why do you only release a list every 3 months?",
      a:
        "By comparing the list in a 3 month interval we get a more consistent tracking of their growth forecast.",
    },
    {
      q: "Why do some columns have the word (SAMPLE) added?",
      a:
        "We have the sample tag to note that the data in that column isn't for the entire lifespan of the account, but for a specific timespan. This is to ensure that the data we show is as much an accurate representation of the influencers current data as possible. And not their historical data.",
    },
    {
      q: "Why does the latest cost more than the previous version?",
      a:
        "In addition to updating all the previous influencers we also add ~20% more influencers each new version.",
    },
    {
      q: "How do i contact influencers when I'm done searching?",
      a:
        "We recommend you reach out to them personally on DM's on the respective platform introducing yourself, to give it a personal touch.",
    },
    {
      q: "I want a refund",
      a: "Contact us at hey@theinfluencerlist.co",
    },
    {
      q: "I got scammed!",
      a:
        "We are sorry to hear that. While we don't have a preexisting releationship with the influencers in our list fraudalent behaviour is not something we tolerate on our list. We can't do anything about your situation but we can stop it from happening to someone else. Please contact us at email@mail.com with details of who it was.",
    },
    {
      q: "I don't want my profile listed on theinfluencerlist",
      a:
        "No worries! Contact us with your profile details and we will remove you.",
    },
    {
      q: "I have a question that isn't answered here",
      a:
        "We are happy to answer all and any questions you have. Reach out to us on hey@theinfluencerlist.co",
    },
  ]

  const stripeCheckout = async (
    event,
    code = "price_1JkshKGlZNC0CUqJF0emwbTR"
  ) => {
    event.preventDefault()

    const stripe = await getStripe()

    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [{ price: "price_1JkshKGlZNC0CUqJF0emwbTR", quantity: 1 }],
      successUrl: `http://www.theinfluencerlist.co/success`,
      cancelUrl: `http://www.theinfluencerlist.co/cancel`,
    })

    if (error) {
      alert(`An error occured ${error.message}`)
    }
  }

  function gtag_report_conversion(url) {
    if (typeof window !== "undefined") {
      var callback = function () {
        if (typeof url != "undefined") {
          window.location = url
        }
      }
      window.gtag("event", "conversion", {
        send_to: "AW-614082583/QpTfCPX_3f0CEJfQ6KQC",
        event_callback: callback,
      })
      return false
    }
  }

  return (
    <Layout>
      <SEO
        title="Micro Influencer Lists"
        description="Find the right influencer for your brand with any of our influencer lists. Choose from one our existing ones or create your own, any platform any niche."
      />
      <div className="map pb-8">
        <header className="text-center max-w-2xl px-4 sm:px-3 mx-auto pt-16 sm:pt-24 xl:pt-32 pb-16 sm:pb-24 md:pb-20 xl:pb-28">
          <h1 className="md:text-7xl sm:text-5.5xl font-extrabold tracking-tight mb-4">
            Grow Your Business
          </h1>
          <p className="text-lg max-w-xl mx-auto mb-8">
            <span className="text-green font-semibold font-sans">
              All the data you need to discover influencers.
            </span>
          </p>
        </header>
        <p
          id="instagram"
          className="text-center text-xl text-gray-700 font-thin"
        >
          Choose a list
        </p>
        <section>
          <form name="list-interest" netlify data-netlify="true" hidden>
            <input type="email" name="email" />
            <input type="hidden" name="platform" />
          </form>
          <ListItem
            logo={instagram}
            platform="instagram"
            header={"Instagram Lists"}
          />
          <ListItem logo={tiktok} platform="tiktok" header="TikTok Lists" />
          <ListItem logo={youtube} platform="youtube" header="YoutTube Lists" />
          <ListItem logo={reddit} platform="reddit" header="Reddit Lists" />
          <ListItem logo={twitch} platform="twitch" header="Twitch Lists" />
          <ListItem logo={twitter} platform="twitter" header="Twitter Lists" />
        </section>
        <p className="w-2/3 mx-auto text-xs font-thin text-gray-600 mb-0">
          DISCLOSURE theinfluencerlist.co is only a influencer aggregator and
          has no relationship or contact with any of the influencers on the
          list. None are sponsored or given preferential treatment, all are
          chosen based on merit and metrics alone. As such any contact or
          arrangements made between you and the influencer falls outside the
          teams liability. By buying any list you agree to not hold the team
          liable for any losses or issues you may incur.
        </p>
      </div>

      <GuaranteeBadge />
      <section className="w-full h-36 bg-grey pt-12 pb-24">
        {/* FAQ */}
        <h2 className="text-center text-3xl uppercase track-wider">
          Frequently Asked Question
        </h2>
        <div>
          <ul className="">
            {faq.map(el => (
              <li className="mx-10 my-8 divide-y-2 divide-gray-400">
                <h3 className="text-xl m-1">{el.q}</h3>
                <p className="italic m-0 pt-2 mb-4 pl-4">{el.a}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export default Lists
